import React from 'react';
import './App.css';

function Image (props){

    return(
        <picture>
            <source type="image/webp" srcSet={props.image}/>
            <img src={props.fallback} alt={props.alt}/>
        </picture>
    );
}

export default Image;