import React, {Component} from 'react';
import './App.css';
import CardWhite from './CardWhite';
import Image from './smartImage';
import RirekishoNinja from './rirekishoNinja.png';
import SkypeMe from './SkypeMe.webp';
import SkypeMeJPG from './SkypeMe.jpg';
import D01CjuvWoAIiuug from './D01CjuvWoAIiuug.webp';
import D01CjuvWoAIiuugJPG from './D01CjuvWoAIiuug.jpg';
import Home from './Home.webp';
import HomeJPG from './Home.jpg';
import AI from './AI.webp';
import AIJPG from './AI.jpg';
import Teaching from './Teaching.webp';
import TeachingJPG from './Teaching.jpg';
import Thesis from './FinalDraft102019.pdf'
import AIPresentation from './Ethics of Self-Driving cars.docx'


class Projects extends Component{
    state = {};
    render(){
        return(
        <div className="siteBodyWhite" id ="projects">
            <CardWhite  title="PROJECTS" body="Projects I've done"/>
            <div className="cardContainer">
                <div className="card">
                    <Image image={RirekishoNinja} alt="Rirekisho Ninja layout"/> 
                    <div className="card-body">
                        <div className="projectCardTitle">Rirekisho Ninja</div>
                        <div className="projectCardName">Created interactive wireframes for a resume development and review system</div>  
                        <div className="projectCardButtonCenter">                      
                        <a className ="projectCardButton" href ="https://xd.adobe.com/view/caf653d9-a3f5-4cb4-7d5a-33ee6a34259d-bcc7/"target="_blank">View Wireframes</a>
                        </div> 
                    </div>
                </div>
                <div className="card">
                    <Image image={SkypeMe} fallback={SkypeMeJPG} alt="Skype Me Easy English Logo"/>
                    <div className="card-body">
                        <div className="projectCardTitle">Skype Me Easy English</div>
                        <div className="projectCardName">Worked with Gwen to build a website, establish a social media plan, branding and Google Analytics.</div> 
                        <div className="projectCardButtonCenter">                      
                        <a className ="projectCardButton" href ="https://www.skypemeeasyenglish.com/"target="_blank">Visit the Website</a>
                        </div> 
                    </div>
                </div>
                <div className="card">
                    <Image image={D01CjuvWoAIiuug} fallback={D01CjuvWoAIiuugJPG} alt="Beat Saber User Interface display"/>
                    <div className="card-body">
                        <div className="projectCardTitle">Senior Thesis</div>
                        <div className="projectCardName">Implementation of User Interface Design within Virtual Reality Applications.</div> 
                        <div className="projectCardButtonCenter">                      
                        <a className ="projectCardButton" href ={Thesis} target="_blank">Read Thesis</a>
                        </div> 
                    </div>
                </div>
                <div className="card">
                    <Image image={Home} fallback={HomeJPG} alt="Image of Biola CSCI Website"/>
                    <div className="card-body">
                        <div className="projectCardTitle">CSCI Website</div>
                        <div className="projectCardName">Scrum Master for Biola CSCI Website Development team using agile methodologies.</div> 
                        <div className="projectCardButtonCenter">                      
                        <a className ="projectCardButton" href ="https://drive.google.com/open?id=1R4ZNBazkA3vfz_nU3g5Lg3pY_AZirzJx"target="_blank">View Project Log</a>
                        </div> 
                    </div>
                </div>
                <div className="card">
                    <Image image={AI} fallback={AIJPG} alt="graphic on # of car accidents in the U.S."/> 
                    <div className="card-body">
                        <div className="projectCardTitle">Ethics of A.I. Presentation</div>
                        <div className="projectCardName">Worked with a team to explore the ethical ramifications of A.I. usage in cars.</div> 
                        <div className="projectCardButtonCenter">                      
                            <a className ="projectCardButton" href ={AIPresentation} target="_blank">Read More</a>
                        </div> 
                    </div>
                </div>
                <div className="card">
                    <Image image={Teaching} fallback={TeachingJPG} alt="image of Hannah with young students"/> 
                    <div className="card-body">
                        <div className="projectCardTitle">Teaching Internship </div>
                        <div className="projectCardName">Created curriculum and taught English to students ranging from 6 to 14 years of age in Okayama, Japan. </div>  
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default Projects;