import React, {useState} from 'react';
import {Animated} from "react-animated-css";
import Logo from './Logo.svg';
import ViewSite from'./viewSite';
import './App.css';


function OpenDoor(){

    const [visible, setVisible] = useState(true);
    const [fade, setFade] = useState(false);

    const doorState = () => {
        setFade(true);
        setTimeout(() => setVisible(false), 650);
    }

    return(
        <div>
            <Animated 
            animationIn="fadeIn"
            animationOut="fadeOut"
            isVisible={!fade}
            style={visible ? null : { display: "none" }}>
                <div className ={"open"} onClick={doorState}>
                    <div className ={"frontDoor"}>
                        <img src = {Logo} alt="click to enter" />
                    </div>
                    <div className={"introText"}>CLICK TO ENTER</div>
                </div>
            </Animated>
            <Animated 
            animationIn="fadeIn"
            style={visible ? {display: "none"} : null }>
                    <ViewSite/>, 
            </Animated>
        </div>
    );
}



export default OpenDoor;