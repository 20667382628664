import React, {Component} from 'react';
import './App.css';
import CardWhite from './CardWhite';
import Resume from './CarsonHannah履歴書.pdf';

class About extends Component{
    state = {};
    render(){
        return(
            <div className="siteBodyWhite" id="about">
                <CardWhite  title="ABOUT" />
                    <div className="cardBody">
                        <div>
                            <i>Technology is the universal language of the new world, and I seek to experience the cultures, lifestyles, and worldviews of other people through it. </i>
                        </div>
                        <div>
                            In middle school, I fell in love with languages. I studied Latin for several years before developing an interest in Japanese which I now speak conversationally. Learning about programming languages and user interface seemed like a reasonable next step. Through my time at university, I discovered a passion for communication that I've taken into every workplace I've had a chance to be a part of. 
                        </div>
                        <div><h3>CERTIFICATIONS</h3></div>
                        <ul>
                            <li>
                                Japanese Language Proficiency Test - N2
                            </li>
                            <li>
                                Google Analytics Individual Certification
                            </li>
                        </ul>
                        <div>
                            <a href={Resume} target="_blank">Click here to download my resume!</a>
                        </div>
                    </div>
            </div>
        )
    }
}

export default About;