import React from 'react';
import './App.css';

function FullpageTitle (props){

    return(
        <div className ="cardGrey">
            <h3>{props.title}</h3>
        </div>
    );
}

export default FullpageTitle;