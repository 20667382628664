import React from 'react';
import './App.css';
import Nav from './Nav';
import Main from './Main';
import About from './About';
import Projects from './Projects';
import TechStack from './TechStack';
import Contact from './Contact';


function ViewSite (){
    return(    
    <div className="siteBody">
        <Nav/>
        <Main/>
        <About/>
        <TechStack/>
        <Projects/>
        <Contact/>
    </div>
    );
}

export default ViewSite;