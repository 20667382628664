import React, {Component} from 'react';
import './App.css';
import {Link} from 'react-scroll';

class Nav extends Component{
    state = {};
    render (){
    return(
        <div className = "nav">
        <ul>
            <Link 
                activeClass="active"
                to= 'main'
                spy={true}
                smooth={true}
                offset={0}
                duration= {500}>
            </Link>
            <Link 
                activeClass="active"
                to= 'about'
                spy={true}
                smooth={true}
                offset={0}
                duration= {500}>
            </Link>
             <Link 
                activeClass="active"
                to= 'techstack'
                spy={true}
                smooth={true}
                offset={0}
                duration= {500}>
            </Link>
            <Link 
                activeClass="active"
                to= 'projects'
                spy={true}
                smooth={true}
                offset={0}
                duration= {500}>
            </Link>
            <Link 
                activeClass="active"
                to= 'contact'
                spy={true}
                smooth={true}
                offset={0}
                duration= {500}>
            </Link>
        </ul>
        </div>
    )
    }
}

export default Nav;