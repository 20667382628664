import React from 'react';
import './App.css';

function CardWhite (props){

    return(
        <div className ="cardWhite">
            <h3>{props.title}</h3>
        </div>
    );
}

export default CardWhite;