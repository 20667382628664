import React from 'react';
import OpenDoor from './frontDoor';
import './App.css';

function App() {

    return (
    <div>
      <OpenDoor/>
    </div>

  );
}
export default App;
