import React, {Component} from 'react';
import './App.css';
import DSC_0156 from './DSC_0156.JPG';

class Main extends Component{
    state = {};
    render (){
        return(
        <div className="siteBody" id="main">
            <div className="main">
                <img className="profile" src={DSC_0156} alt="profile picture"/>
                <div>
                <h3>HANNAH CARSON</h3>
                <p>WEB DEVELOPMENT + UI DESIGN</p>
                </div>
                
            </div>
        </div>
        )
    }
}

export default Main;