import React, {Component} from 'react';
import './App.css';
import FullpageTitle from './title';

class TechStack extends Component{
    state = {};
    render (){
        return(
        <div className="siteBody" id="techstack">
            <FullpageTitle  title="TECH STACK" />
            <div className="techstackDiv">
                <div className="techstackColumn">
                    <h3>LANGUAGES</h3>
                    <div className= "progress-label">HTML</div>
                    <div className= "progress" label ={"HTML"}> <span className= "progress-bar" style={{width: "90%"}}>90%</span> </div>
                    <div className= "progress-label">CSS</div>
                    <div className= "progress" label ={"CSS"}> <span className= "progress-bar" style={{width: "90%"}}>90%</span> </div>
                    <div className= "progress-label">C++</div>
                    <div className= "progress" label ={"C++"}> <span className= "progress-bar" style={{width: "90%"}}>90%</span> </div>
                    <div className= "progress-label">JavaScript</div>
                    <div className= "progress" label ={"JavaScript"}> <span className= "progress-bar" style={{width: "70%"}}>70%</span> </div>
                    <div className= "progress-label">SQL</div>
                    <div className= "progress" label ={"SQL"}> <span className= "progress-bar" style={{width: "60%"}}>60%</span> </div>
                    <div className= "progress-label">C#</div>
                    <div className= "progress" label ={"C#"}> <span className= "progress-bar" style={{width: "60%"}}>60%</span> </div>
                    <div className= "progress-label">Python</div>
                    <div className= "progress" label ={"Python"}> <span className= "progress-bar" style={{width: "40%"}}>40%</span> </div>                   
                </div>
                <div className = "techstackColumn">
                    <h3>TOOLKIT</h3>
                    <div className= "progress-label">Laravel/PHP</div>
                    <div className= "progress" label ={"aravel/PHP"}> <span className= "progress-bar" style={{width: "80%"}}>80%</span> </div>
                    <div className= "progress-label">WordPress</div>
                    <div className= "progress" label ={"WordPress"}> <span className= "progress-bar" style={{width: "80%"}}>80%</span> </div>
                    <div className= "progress-label">Vue</div>
                    <div className= "progress" label ={"Vue"}> <span className= "progress-bar" style={{width: "60%"}}>60%</span> </div>
                    <div className= "progress-label">React</div>
                    <div className= "progress" label ={"React"}> <span className= "progress-bar" style={{width: "60%"}}>60%</span> </div>
                    <div className= "progress-label">Unity</div>
                    <div className= "progress" label ={"Unity"}> <span className= "progress-bar" style={{width: "50%"}}>50%</span> </div>
                    <div className= "progress-label">Git</div>
                    <div className= "progress" label ={"Git"}> <span className= "progress-bar" style={{width: "70%"}}>70%</span> </div>
                    <div className= "progress-label">Adobe XD</div>
                    <div className= "progress" label ={"Adobe XD"}> <span className= "progress-bar" style={{width: "80%"}}>80%</span> </div>
                    <div className= "progress-label">Illustrator</div>
                    <div className= "progress" label ={"Illustrator"}> <span className= "progress-bar" style={{width: "60%"}}>60%</span> </div>
                    <div className= "progress-label">Sketch</div>
                    <div className= "progress" label ={"Sketch"}> <span className= "progress-bar" style={{width: "50%"}}>50%</span> </div>
                </div>
            </div>
        </div>
        )
    }
}

export default TechStack;