import React, {Component} from 'react';
import { Link } from "react-router-dom";
import './App.css';
import FullpageTitle from './title';
import Twitter from'./SocialMedia/iconmonstr-twitter-4-96.png'
import LinkedIn from './SocialMedia/iconmonstr-linkedin-4-96.png'
import Line from './SocialMedia/iconmonstr-line-4-96.png'
import Instagram from './SocialMedia/iconmonstr-instagram-14-96.png'
import Gmail from './SocialMedia/iconmonstr-gmail-4-96.png'
import Dribbble from './SocialMedia/iconmonstr-dribbble-4-96.png'



class Contact extends Component{
    state = {};
    render(){
        return(
            <div className="siteBody" id="contact">
                <FullpageTitle  title="CONTACT"/>
                <div className="cardBody">
                    <div className="contactText">
                        <div>Let's connect!</div>
                        <div>Check out my social media below or email me at <a href ="mailto:hannah.e.carson@biola.edu">hannah.e.carson@biola.edu</a></div>
                        </div>
                    <div className = "socialMediaContainer">
                        <a href="mailto:hannah.e.carson@biola.edu">
                            <img src ={Gmail}/>
                        </a>
                        <a href="https://www.linkedin.com/in/hannah-elise-carson/"target="_blank">
                            <img src ={LinkedIn}/>
                        </a>
                        <a href="https://twitter.com/awkwardlyjoyful"target="_blank">
                            <img src ={Twitter}/>
                        </a>
                        <a href="https://dribbble.com/awkwardlyjoyful"target="_blank">
                            <img src ={Dribbble}/>
                        </a>
                        <a href="https://www.instagram.com/awkwardlyjoyful/"target="_blank">
                            <img src ={Instagram}/>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact;